/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useMaterialUIController } from "/context";
import dynamic from "next/dynamic";
import OurProcessImage from "/assets/img/our-process.jpg";
import { robotoSlab } from "/utils/fonts";
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const Timeline = dynamic(() => import("@mui/lab/Timeline"), { loading: () => null });
const TimelineSeparator = dynamic(() => import("@mui/lab/TimelineSeparator"), { loading: () => null });
const TimelineConnector = dynamic(() => import("@mui/lab/TimelineConnector"), { loading: () => null });
const TimelineContent = dynamic(() => import("@mui/lab/TimelineContent"), { loading: () => null });
const TimelineDot = dynamic(() => import("@mui/lab/TimelineDot"), { loading: () => null });
const MDBadge = dynamic(() => import('/components/MDBadge'), { loading: () => null });
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });
const ALLDVideo = dynamic(() => import('/examples/ALLDVideo'), { loading: () => null });

function TimeLineEntry({ header, body }) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot variant="filled" color="info" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <MDTypography variant="h6" component="h3">{header}</MDTypography>
        <MDTypography variant="body2" fontWeight="regular">{body}</MDTypography>
      </TimelineContent>
    </TimelineItem>
  )
}

function OurProcess() {
  const [controller] = useMaterialUIController();
  return (
    <MDBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={7}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MDBadge
            variant="contained"
            color={controller.sidenavColor}
            badgeContent="Our Process"
            container
            sx={{ mb: 2 }}
          />
          <MDTypography variant="h2" fontWeight="bold" className={robotoSlab.className}>
            Comprehensive Design & Build Process
          </MDTypography>
          <MDTypography variant="body1" color="text">
            Our Process is easy and takes the stress out of renovating your landscape.
          </MDTypography>
        </Grid>
      </Container>
      <Container>
        <MDBox
          display="flex"
          spacing={3}
          alignItems="center"
          justifyContent="center">
          <Grid container item xs={12} lg={9} spacing={3}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="body2" mb={1} fontWeight="regular">
                <strong>Our Full-Service Design and Build Process</strong> lets us show you your landscape with the new features in 3D before we build so you know it'll come out right before we build.
              </MDTypography>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <TimeLineEntry header="Plan" body="We identify the features and arrangement." />
                <TimeLineEntry header="Design" body="We design the plan in 3D and perfect it with you." />
                <TimeLineEntry header="Prep" body="We obtain approvals, permits and materials." />
                <TimeLineEntry header="Build" body="We build the design and cleanup." />
              </Timeline>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h4" component="h3">Watch our process video</MDTypography>
              <MDTypography variant="body2" mb={3} fontWeight="regular">Introduction to our process</MDTypography>
              <ALLDVideo row={{ url: OurProcessImage, title: "Our Process", vid: "-lq8A9N2CgM" }} />
            </Grid>
          </Grid>
        </MDBox>
      </Container>
      <br />
    </MDBox>
  );
}

export default OurProcess;
